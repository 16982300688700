.langTabs {
    position: fixed;
    top: 5.6vw;
    right: 6vw;
    display: flex;
    align-items: center;
    height: 6vw;
    background-color: #FAFAFA;
    border-radius: 3vw;
    z-index: 2;
    pointer-events: auto;
}

.langTab {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 11vw;
    border-radius: 50%;
}

.langTab:before {
    content: '';
    position: absolute;
    top: -3vw;
    bottom: -3vw;
    left: 0;
    right: 0;
}

.langTab:nth-child(1):before {
    left: -3vw;
}

.langTab:nth-child(2):before {
    right: -3vw;
}

.langTab.disabled {
    pointer-events: none;
}

.langTabTitle {
    position: relative;
    z-index: 1;
    font-size: 3vw;
    color: var(--colorBlack);
    transition: color var(--trDur), opacicy var(--trDur);
}

.langTab.active .langTabTitle {
    color: var(--colorWhite);
}

.langTab.disabled .langTabTitle {
    opacity: 0.5;
}

.langTabBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6vw;
    background-color: var(--colorBlack);
}