.inner {
  position: relative;
  padding-bottom: 124%;
  border-radius: 2.666666vw;
  margin-bottom: 4vw;
}

.infoIconBox {
  position: absolute;
  top: 3vw;
  right: 3vw;
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  background-color: var(--colorGray);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.infoIconBox:before {
  content: '';
  position: absolute;
  top: -2.5vw;
  bottom: -2.5vw;
  left: -2.5vw;
  right: -2.5vw;
}

.infoIcon {
  position: relative;
  width: 1.2vw;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colorLightGray);
  border-radius: 2.666666vw;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productImageBox {
  position: relative;
  width: 20.666666vw;
  min-width: 20.666666vw;
  height: 20.666666vw;
  margin-top: 1vw;
}

.productImage {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  z-index: 1;
  padding: 2.5vw;
}

.title {
  font-size: 3.4vw;
  color: var(--colorBlack);
  margin-top: 2vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform var(--trDur) ease-out;
}

.item.choosed .title,
.item.outOfStock .title {
  transform: translateY(-1vw);
}

.subTitle {
  font-size: 2.8vw;
  color: var(--colorBlack-050);
  margin-top: 3vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform var(--trDur) ease-out;
}

.item.choosed .subTitle,
.item.outOfStock .subTitle {
  transform: translateY(-2.5vw);
}

.price {
  font-size: 2.8vw;
  color: var(--colorBlack);
  margin-top: 3vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform var(--trDur) ease-out;
}

.item.choosed .price,
.item.outOfStock .price {
  transform: translateY(-3.8vw);
}

.quantityBox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3.2vw;
  margin: auto;
}

.outOfStockTitle {
  font-size: 2.8vw;
  color: var(--colorError);
}