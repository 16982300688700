.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  opacity: 0;
  background-color: transparent;
}

.layer.disabled {
  pointer-events: none;
}