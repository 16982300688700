.quantityBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityBtn {
  position: relative;
  width: 7.4vw;
  height: 7.4vw;
  background-color: var(--colorBlack);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--trDur);
}

.quantityBtn.disabled {
  opacity: 0.3;
}

.quantityBtn:before {
  content: '';
  position: absolute;
  top: -2.5vw;
  bottom: -2.5vw;
  left: -2.5vw;
  right: -2.5vw;
}

.quantityBtnIcon {
  position: relative;
  width: 2.4vw;
}

.inputField {
  width: 10vw;
  text-align: center;
  font-size: 3.4vw;
  color: var(--colorBlack);
}