.shape {
    position: absolute;
    top: 12vw;
    width: 100vw;
    pointer-events: none;
}

.shape:global(.top) {
    top: 0;
}

.shape:global(.center) {
    top: 12vw;
}

.shape:global(.bottom) {
    top: 40vw;
}

.shape:global(.left) {
    left: -50vw;
}

.shape:global(.right) {
    right: -50vw;
}