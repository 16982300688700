.topBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 12vw;
}

.title {
  font-size: 9vw;
  color: var(--colorBlack);
  margin-top: 4.833333vw;
  text-align: center;
}

.helperText {
  font-size: 4vw;
  color: var(--colorBlack);
  margin-top: 17vw;
  text-align: center;
  max-width: 70vw;
}

.loaderBox {
  /* position: relative; */
  position: absolute;
  bottom: 16vw;
  /* margin-top: 9vw; */
  width: 21vw;
  height: 21vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transform: scale(0.7);
}

.animationBox {
  display: flex;
  height: 10vw;
  width: 10vw;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes paymentLoaderAnimation {
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes paymentLoaderAnimation {
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.animationBoxInner {
  position: relative;
  top: -10px;
  left: -4px;
}

.animationBoxInner > div:nth-child(1) {
  top: 20px;
  left: 0;
  animation: paymentLoaderAnimation 1.2s -0.84s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(2) {
  top: 13.63636px;
  left: 13.63636px;
  transform: rotate(-45deg);
  animation: paymentLoaderAnimation 1.2s -0.72s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(3) {
  top: 0;
  left: 20px;
  transform: rotate(90deg);
  animation: paymentLoaderAnimation 1.2s -0.6s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(4) {
  top: -13.63636px;
  left: 13.63636px;
  transform: rotate(45deg);
  animation: paymentLoaderAnimation 1.2s -0.48s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(5) {
  top: -20px;
  left: 0;
  animation: paymentLoaderAnimation 1.2s -0.36s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(6) {
  top: -13.63636px;
  left: -13.63636px;
  transform: rotate(-45deg);
  animation: paymentLoaderAnimation 1.2s -0.24s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(7) {
  top: 0;
  left: -20px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  animation: paymentLoaderAnimation 1.2s -0.12s infinite ease-in-out;
}

.animationBoxInner > div:nth-child(8) {
  top: 13.63636px;
  left: -13.63636px;
  transform: rotate(45deg);
  animation: paymentLoaderAnimation 1.2s 0s infinite ease-in-out;
}

.animationBoxInner > div {
  background-color: #000;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  width: 5px;
  height: 15px;
}
