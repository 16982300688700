.items {
  padding-bottom: 22vw;
  margin-top: 1vw;
}

.item {
  margin-bottom: 4vw;
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--colorLightGray);
  border-radius: 2.666666vw;
  padding: 8vw 5vw;
  min-height: 24vw;
}

.radioInput {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.radioIcon {
  position: relative;
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  border: 1px solid var(--colorGrayBorder2);
  background-color: white;
  margin-right: 2.666666vw;
  transition: background-color var(--trDur), border-color var(--trDur);
}

.radioIcon:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 2.5vw;
  height: 1.2vw;
  border-bottom: 1px solid var(--colorWhite);
  border-left: 1px solid var(--colorWhite);
}

.radioInput:checked ~ .radioIcon {
  background-color: var(--colorBlack);
  border: 1px solid var(--colorBlack);
}

.radioTitle {
  font-size: 3.4vw;
}

.paymentImages {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentImages img {
  margin-left: 3vw;
  max-width: 16.4vw;
  max-height: 6.4vw;
}

.findYourTasteBox {
  width: 100%;
  background-color: var(--colorLightGray);
  border-radius: 2.666666vw;
  padding: 3vw 5vw 8vw 5vw;
  margin-top: -7vw;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fieldBoxItem {
    margin-top: 5vw;
}

.fieldBoxItem:first-child {
    margin-top: 0;
}

.input {
  display: block;
  width: 100%;
  height: 14vw;
  border: 1px solid var(--colorGrayBorder2);
  border-radius: 1.4vw;
  padding: 0 4vw;
  font-size: 3.4vw;
  color: var(--colorBlack);
  box-shadow: none;
  background-color: var(--colorWhite);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.input::placeholder {
  color: var(--colorBlack);
  transition: opacity 0.2s;
}

.input:focus::placeholder {
  opacity: 0;
}

.textarea {
    display: block;
    width: 100%;
    height: 24vw;
    border: 1px solid var(--colorGrayBorder2);
    border-radius: 1.4vw;
    padding: 4vw 4vw;
    font-size: 3.4vw;
    color: var(--colorBlack);
    box-shadow: none;
    background-color: var(--colorWhite);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    resize: none;
}

.textarea::placeholder {
    color: var(--colorBlack);
    transition: opacity 0.2s;
  }
  
  .textarea:focus::placeholder {
    opacity: 0;
  }