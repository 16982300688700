@font-face {
  font-family: "MeamaLGV-Medium";
  src: url("../fonts/MeamaLGV-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MeamaSansLGV-Medium";
  src: url("../fonts/MeamaSansLGV-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MeamaSansLGV-Regular";
  src: url("../fonts/MeamaSansLGV-Regular.ttf") format("truetype");
  font-display: swap;
}

:root {
  --trDur: 0.3s;
  --colorBlack: #000000;
  --colorWhite: #ffffff;
  --colorGray: #efefef;
  --colorGrayBorder: rgba(88, 88, 88, 0.15);
  --colorGrayBorder2: rgba(88, 88, 88, 0.3);
  --colorLightGray: #fafafa;
  --colorBlack-050: rgba(0, 0, 0, 0.5);
  --colorBlack-010: rgba(0, 0, 0, 0.1);
  --colorPurple: #5c0bc5;
  --colorError: #fc3e3a;

  --colorButton: #ffffff;
  --colorButtonBg: #000000;
  --colorButtonBorder: #000000;

  --colorButtonFilled: #ffffff;
  --colorButtonFilledBg: #000000;
  --colorButtonFilledBorder: #000000;

  --colorButtonTransparent: #000000;
  --colorButtonTransparentBg: transparent;
  --colorButtonTransparentBorder: rgba(0, 0, 0, 0.1);

  --colorBackdropBg: rgba(0, 0, 0, 0.8);
}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

* {
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--colorBlack);
  background-color: var(--colorWhite);
  font-family: "MeamaSansLGV-Regular" !important;
  font-weight: 100 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

body.disable-scroll {
  overflow: hidden;
}

.pageWrap {
  position: relative;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 *,
h2 *,
h3 *,
h4 *,
h5 *,
h6 * {
  font-weight: normal !important;
}

.f-lgv,
.f-lgv * {
  font-family: "MeamaLGV-Medium" !important;
}

.f-bold,
.f-bold * {
  font-family: "MeamaSansLGV-Medium" !important;
}

.f-upper {
  text-transform: uppercase !important;
  font-feature-settings: "case" on !important;
  -moz-font-feature-settings: "case" !important;
  -webkit-font-feature-settings: "case" !important;
}

.f-normal,
.f-normal * {
  font-family: "MeamaSansLGV-Regular" !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

#productDetailsBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  pointer-events: none;
}

#productDetailsBox * {
  pointer-events: auto;
}

.top-fixed-content {
  position: sticky;
  top: 17vw;
  background-color: var(--colorWhite);
  z-index: 2;
  padding: 0 6vw 4vw 6vw;
  overflow: hidden;
}

.page-content {
  position: relative;
  padding: 0 6vw;
  overflow: hidden;
  min-height: calc((var(--vh, 1vh) * 100) - 17vw);
}

/* Sheet Modal */

.react-modal-sheet-backdrop {
  background-color: var(--colorBackdropBg) !important;
  border: 0;
  box-shadow: none;
}

.react-modal-sheet-container {
  border-top-right-radius: 8vw !important;
  border-top-left-radius: 8vw !important;
}

.react-modal-sheet-header {
  height: 6.666666vw;
}

.react-modal-sheet-drag-indicator {
  width: 10vw !important;
  height: 1.333333vw !important;
  transform: none !important;
  border-radius: 1.333333vw !important;
}

.react-modal-sheet-drag-indicator:nth-child(1) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-modal-sheet-drag-indicator:nth-child(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.react-modal-sheet-content {
  padding: 3vw 6vw 0 6vw;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#landscapeScreen {
  display: none;
}

@media only screen and (max-device-width: 480px) and (orientation: landscape) {
  body {
    overflow: hidden !important;
  }

  #landscapeScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--colorWhite);
    z-index: 9999999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw 7vw;
    color: var(--colorBlack);
    font-size: 3vw;
  }
}
