.topBox {
  padding-bottom: 6.666666vw;
}

.mainTitle {
  font-size: 6vw;
  color: var(--colorBlack);
  padding: 0px 4vw;
}

.textBox {
  flex: 1;
  overflow: auto;
  pointer-events: auto !important;
  margin-left: -6vw;
  margin-right: -6vw;
  padding-left: 6vw;
  padding-right: 6vw;
  padding-bottom: 6vw;
}

.text {
  text-align: left;
  font-size: 3.2vw;
  line-height: 4vw;
  pointer-events: none !important;
}

.text ul {
  margin-top: -1.5vw;
}

.text ul li {
  position: relative;
  padding: 0 0 0 6vw;
  margin: 1.5vw 0;
}

.text ul li:before {
  content: "";
  position: absolute;
  top: 1.5vw;
  left: 3vw;
  width: 0.8vw;
  height: 0.8vw;
  background-color: var(--colorBlack);
  border-radius: 50%;
}
