.totalPriceBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 18vw;
    background-color: var(--colorWhite);
    z-index: 1;
    border-top: 1px solid var(--colorGray);
    padding: 2vw 6vw 2vw 6vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label {
    font-size: 2.8vw;
    color: var(--colorBlack-050);
    margin: 1.5vw 0;
}

.price {
    font-size: 5.4vw;
    color: var(--colorBlack);
    margin: 1.5vw 0;
}

.buttonBox {
    width: 24vw;
}
