.topBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20vw;
}

.icon {
    width: 9vw;
}

.title {
    font-size: 9vw;
    color: var(--colorError);
    margin-top: 4.833333vw;
}

.text {
    font-size: 3.4vw;
    color: var(--colorBlack);
    margin-top: 3vw;
    text-align: center;
    max-width: 70vw;
}

.button {
    position: absolute;
    bottom: 16vw;
    z-index: 2;
}