.pageProducts {
    margin-top: -17vw;
    padding-top: 17vw;
}

.pageProducts :global(.page-content) {
    min-height: inherit !important;
}

.backBtn {
    position: relative;
    font-size: 4vw;
    color: var(--colorBlack);
    display: flex;
    align-items: center;
    width: max-content;
    margin-top: 3.8vw;
    margin-bottom: 2.8vw;
}

.backBtn img {
    position: relative;
    top: 0.2vw;
    width: 2vw;
    margin-right: 3vw;
}

.backBtn:before {
    content: '';
    position: absolute;
    top: -3.5vw;
    bottom: -3.5vw;
    left: -3.5vw;
    right: -3.5vw;
}

.tabs {
    display: flex;
    align-items: center;
    height: 12vw;
    background-color: #FAFAFA;
    border-radius: 6vw;
    padding: 1vw;
    margin-top: 4vw;
}

.tab {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50%;
    height: 10vw;
    border-radius: 5vw;
}

.tab.disabled {
    pointer-events: none;
}

.tabTitle {
    position: relative;
    z-index: 1;
    font-size: 3.2vw;
    color: var(--colorBlack);
    transition: color var(--trDur) .1s, opacicy var(--trDur);
}

.tab.active .tabTitle {
    color: var(--colorWhite);
}

.tab.disabled .tabTitle {
    opacity: 0.5;
}

.tabBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5vw;
    background-color: var(--colorBlack);
}