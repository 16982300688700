.overlay {
  background: var(--colorBackdropBg);
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.closeBtn {
  position: absolute;
  top: 5vw;
  right: 5vw;
  width: 11vw;
  height: 11vw;
  background-color: var(--colorWhite);
  border-radius: 50%;
  z-index: 3;
}

.closeBtn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.6vw;
  max-width: 100%;
  max-height: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 85%;
  margin: auto;
  width: 100vw;
  background-color: var(--colorWhite);
  z-index: 2;
  padding: 0 6vw 0 6vw;
}

.imgBox {
  width: 40vw;
  height: 40vw;
  margin: -16vw auto 0 auto;
  pointer-events: none !important;
  padding: 4vw;
}

.img {
  display: block;
  width: 100%;
  pointer-events: none !important;
}

.topInfo .title {
  font-size: 7.6vw;
  text-align: center;
  color: var(--colorBlack);
}

.topInfo .roast {
  font-size: 3.4vw;
  text-align: center;
  color: var(--colorBlack-050);
  margin-top: 3vw;
}

.topInfo .country {
  font-size: 3.4vw;
  text-align: center;
  color: var(--colorBlack);
  margin-top: 2.5vw;
}

.line {
  height: 1px;
  background-color: var(--colorGray);
  margin: 5vw 0;
}

.scroller {
  overflow: auto;
  margin-top: -5vw;
  padding-top: 5vw;
  margin-right: -6vw;
  padding-right: 6vw;
  padding-bottom: 6vw;
}

.specifications .title {
  font-size: 5vw;
  color: var(--colorBlack);
  margin-bottom: 6vw;
}

.specifications .specificationCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4vw 0;
}

.specifications .specificationCont .left {
  margin-right: auto;
  font-size: 3.2vw;
  color: var(--colorBlack-050);
}

.specifications .specificationCont .center {
  width: 45vw;
  min-width: 45vw;
  height: 2px;
  background-color: var(--colorGray);
}

.specifications .specificationCont .progressLine {
  height: 100%;
  background-color: var(--colorBlack);
}

.specifications .specificationCont .right {
  width: 13.5vw;
  min-width: 13.5vw;
  text-align: right;
  font-size: 3.2vw;
  color: var(--colorBlack);
}

.additionalInfo .additionalInfoCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4vw 0;
}

.additionalInfo .additionalInfoCont .left {
  width: 50%;
  min-width: 50%;
  font-size: 3.2vw;
  color: var(--colorBlack-050);
}

.additionalInfo .additionalInfoCont .right {
  width: 50%;
  min-width: 50%;
  font-size: 3.2vw;
  color: var(--colorBlack);
  text-align: right;
}

.description {
  margin-top: 6vw;
}

.description .title {
  font-size: 5vw;
  color: var(--colorBlack);
  margin-bottom: 4vw;
}

.description .text {
  font-size: 3.2vw;
  color: var(--colorBlack-050);
  line-height: 4vw;
}