.header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17vw;
    overflow: hidden;
    background-color: var(--colorWhite);
    z-index: 2;
    pointer-events: none !important;
}

.logo {
    position: relative;
    width: 8vw;
}

.logoImg {
    display: block;
    width: 100%;
}

.logo:before {
    content: '';
    position: absolute;
    top: -1vw;
    bottom: -1vw;
    left: -1vw;
    right: -1vw;
}