.topBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 18vw;
}

.title {
    font-size: 8vw;
    color: var(--colorBlack);
    margin-top: 4.833333vw;
    text-align: center;
}

.button {
    margin-top: 14vw;
    z-index: 2;
}